<template>
  <div class="text-xs">
    <div class="flex w-full items-center justify-center md:justify-start ml-px flex-wrap gap-4 md:min-w-80 md:ml-1">
      <div class="flex flex-col items-center flex-wrap">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
          <path
            fill="#000000"
            d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10s10-4.49 10-10S17.51 2 12 2M3.01 12c0-1.3.28-2.54.78-3.66l4.29 11.75c-3-1.46-5.07-4.53-5.07-8.09M12 20.99c-.88 0-1.73-.13-2.54-.37l2.7-7.84l2.76 7.57c.02.04.04.09.06.12c-.93.34-1.93.52-2.98.52m1.24-13.21c.54-.03 1.03-.09 1.03-.09c.48-.06.43-.77-.06-.74c0 0-1.46.11-2.4.11c-.88 0-2.37-.11-2.37-.11c-.48-.02-.54.72-.05.75c0 0 .46.06.94.09l1.4 3.84l-1.97 5.9l-3.27-9.75c.54-.02 1.03-.08 1.03-.08c.48-.06.43-.77-.06-.74c0 0-1.46.11-2.4.11c-.17 0-.37 0-.58-.01C6.1 4.62 8.86 3.01 12 3.01c2.34 0 4.47.89 6.07 2.36c-.04 0-.08-.01-.12-.01c-.88 0-1.51.77-1.51 1.6c0 .74.43 1.37.88 2.11c.34.6.74 1.37.74 2.48c0 .77-.3 1.66-.68 2.91l-.9 3zm6.65-.09a8.99 8.99 0 0 1-3.37 12.08l2.75-7.94c.51-1.28.68-2.31.68-3.22c0-.33-.02-.64-.06-.92"
          />
        </svg>
        <p class="font-bold mt-px">Wordpress</p>
      </div>
      <div class="flex flex-col items-center flex-wrap">
        <svg
          class="img-fluid"
          id="outputsvg"
          xmlns="http://www.w3.org/2000/svg"
          style="transform: none; transform-origin: 50% 50% 0px; cursor: move; transition: none"
          width="32"
          height="32"
          viewBox="0 0 10000 4600"
        >
          <g id="l66drJk1xhsYlkeiZGHmT47" fill="#000000" style="transform: none">
            <g>
              <path
                id="p13VVc1q48"
                d="M7050 4204 l0 -395 -80 85 c-361 381 -878 631 -1419 687 -228 23 -551 -1 -776 -60 -93 -24 -258 -79 -329 -110 -27 -12 -51 -20 -53 -17 -2 2 12 43 31 90 l35 87 -607 -7 c-334 -4 -609 -9 -612 -11 -3 -3 -61 -147 -130 -321 l-126 -317 -229 -6 c-127 -4 -478 -7 -781 -8 l-551 -1 -129 335 -129 335 -579 0 c-546 0 -578 -1 -572 -17 4 -10 410 -1037 902 -2283 l896 -2265 385 5 384 5 285 690 c156 380 296 718 310 753 l27 63 42 -100 c303 -712 952 -1221 1725 -1353 149 -25 430 -35 581 -19 541 56 1058 306 1419 687 l80 85 0 -400 0 -401 1475 0 1475 0 0 525 0 525 -930 0 -930 0 0 420 0 420 875 0 875 0 0 510 0 510 -875 0 -875 0 0 835 0 835 -545 0 -545 0 0 -396z m-1520 -638 c344 -52 662 -255 855 -546 52 -79 116 -206 139 -277 l18 -53 254 0 254 0 0 -375 0 -375 -254 0 -254 0 -18 -52 c-9 -29 -35 -89 -57 -133 -217 -437 -647 -705 -1132 -705 -558 0 -1047 361 -1209 892 -204 667 169 1374 833 1582 173 55 383 70 571 42z m-2940 -694 c0 -8 -361 -997 -381 -1042 -9 -20 -12 -22 -19 -10 -4 8 -55 152 -113 320 -58 168 -139 403 -181 523 l-75 217 384 0 c212 0 385 -4 385 -8z"
              ></path>
            </g>
          </g>
        </svg>
        <p class="font-bold mt-px">ACF</p>
      </div>
      <div class="flex flex-col items-center flex-wrap">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
          <g fill="none">
            <g fill="#000000" clip-path="url(#akarIconsVscodeFill0)">
              <path d="M.228 8.37s-.584-.427.117-.995L1.98 5.897s.467-.497.962-.064l15.081 11.542v5.534s-.007.87-1.11.774z" />
              <path
                d="M4.116 11.937L.228 15.509s-.4.3 0 .837l1.805 1.66s.429.465 1.062-.065l4.121-3.158zm6.824.029l7.13-5.502l-.047-5.505s-.305-1.202-1.32-.576L7.216 9.11z"
              />
              <path
                d="M16.912 23.69c.414.428.916.288.916.288l5.556-2.767c.711-.49.611-1.098.611-1.098V3.588c0-.726-.735-.977-.735-.977L18.444.264c-1.052-.657-1.741.119-1.741.119s.886-.645 1.32.576v21.85c0 .15-.032.297-.095.43c-.127.259-.402.5-1.062.4z"
              />
            </g>
            <defs>
              <clipPath id="akarIconsVscodeFill0">
                <path fill="#fff" d="M0 0h24v24H0z" />
              </clipPath>
            </defs>
          </g>
        </svg>
        <p class="font-bold mt-px">VsCode</p>
      </div>
      <div class="flex flex-col items-center flex-wrap">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512">
          <rect width="448" height="416" x="32" y="48" fill="none" stroke="#000000" stroke-linejoin="round" stroke-width="38" rx="48" ry="48" />
          <path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="38" d="m96 112l80 64l-80 64m96 0h64" />
        </svg>
        <p class="font-bold mt-px">SSH</p>
      </div>
    </div>
    <h3 class="mt-5 mb-2">{{ $t('common.context') }}</h3>
    <p class="mb-2">{{ $t('project.emc2.contextFirstParagraph') }}</p>
    <p class="mb-2">{{ $t('project.emc2.contextSecondParagraph') }}</p>
    <p class="mb-2">{{ $t('project.emc2.contextThirdParagraph') }}</p>
    <p class="mb-2">{{ $t('project.emc2.contextFourthParagraph') }}</p>
    <h3 class="mt-5 mb-2">{{ $t('common.objectives') }}</h3>
    <ul class="list-disc ml-3">
      <li>{{ $t('project.emc2.goalFirst') }}</li>
      <li>{{ $t('project.emc2.goalSecond') }}</li>
      <li>{{ $t('project.emc2.goalThird') }}</li>
      <li>{{ $t('project.emc2.goalFourth') }}</li>
      <li>{{ $t('project.emc2.goalFifth') }}</li>
      <li>{{ $t('project.emc2.goalSixth') }}</li>
    </ul>
  </div>
  <a
    rel="noopener"
    href="https://www.lemc2.com/agenda"
    target="_blank"
    class="absolute bottom-2 right-1 md:right-6 h-6 text-xxs border border-twilight-blue bg-button-submit rounded-sm leading-loose px-3 hover:shadow-button-submit-hover cursor-pointer active:bg-button-clicked flex items-center justify-center"
  >
    <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 mr-0.5" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M16.36 14c.08-.66.14-1.32.14-2s-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2m-5.15 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 0 1-4.33 3.56M14.34 14H9.66c-.1-.66-.16-1.32-.16-2s.06-1.35.16-2h4.68c.09.65.16 1.32.16 2s-.07 1.34-.16 2M12 19.96c-.83-1.2-1.5-2.53-1.91-3.96h3.82c-.41 1.43-1.08 2.76-1.91 3.96M8 8H5.08A7.92 7.92 0 0 1 9.4 4.44C8.8 5.55 8.35 6.75 8 8m-2.92 8H8c.35 1.25.8 2.45 1.4 3.56A8 8 0 0 1 5.08 16m-.82-2C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2s.06 1.34.14 2M12 4.03c.83 1.2 1.5 2.54 1.91 3.97h-3.82c.41-1.43 1.08-2.77 1.91-3.97M18.92 8h-2.95a15.7 15.7 0 0 0-1.38-3.56c1.84.63 3.37 1.9 4.33 3.56M12 2C6.47 2 2 6.5 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2"
      />
    </svg>
    {{ $t('buttons.linkWebSite') }}
  </a>
</template>
